export const appConstants = {
  JWT_TOKEN_KEY: "jwttoken",
  USER_PHONE_KEY: "userphone",
  CURRENT_USER: "current_user",
  API_URL: "https://staging.api.sportaabe.com/api/v2", 
  ENV: "prod",
  IS_JSON: true,
  ADMIN_PHONES: ["237690210677", "237673029034","237657670007"],
  dataTableSettings: {
    pageLength: 10,
    ordering: false,
    retrieve: false,
    lengthMenu: [
      [5, 10, 25, 50, 100, -1],
      [5, 10, 25, 50, 100, "All"],
    ],
    language: {
      lengthMenu: "Afficher _MENU_",
      zeroRecords: "Tableau vide",
      info: "Page _PAGE_ sur _PAGES_",
      infoEmpty: "Aucune donnée disponible.",
      infoFiltered: "(filtrage de _MAX_ enregistrements.)",
      sSearch: "Rechercher",
      oPaginate: {
        sFirst: "Premier",
        sPrevious: "Précédent",
        sNext: "Suivant",
        sLast: "Dernier",
      },
    },
  },
  dataTableSettingsServer: {
    pageLength: 10,
    ordering: false,
    retrieve: false,
    lengthMenu: [
      [5, 10, 25, 50, 100, -1],
      [5, 10, 25, 50, 100, "All"],
    ],
    language: {
      lengthMenu: "Afficher _MENU_",
      zeroRecords: "Tableau vide",
      info: "Page _PAGE_ sur _PAGES_",
      infoEmpty: "Aucune donnée disponible.",
      infoFiltered: "(filtrage de _MAX_ enregistrements.)",
      sSearch: "Rechercher",
      oPaginate: {
        sFirst: "Premier",
        sPrevious: "Précédent",
        sNext: "Suivant",
        sLast: "Dernier",
      },
    },
    ajax: (d: any, cb: any) => {
      console.log(d);
      cb({
        ...d,
        recordsTotal: 2000,
        recordsFiltered: 1,
        data: [

          {
            active:
              "1",
            category_id
              :
              "69",
            city
              :
              "",
            company_id
              :
              "44be62bb-46f6-419c-823e-972486231e13",
            country
              :
              "",
            id
              :
              "2b5fb105-826c-4503-903e-2d46417a863b",
            latitude
              :
              "0.0000000",
            long_description
              :
              "Description :\n\nDurée : \n\nNiveau :\n\nInstructions vestimentaires :\n\nMatériel que vous devez apporter :\n\nRestriction de genre :\n\nL’activité s'adresse à :\n\nHandicaps et conditions adaptés :\n\nModes de paiement acceptés :\n\nConvient aux âges : 21\n\nVestiaires :\n\nDouches :\n\nAccessible aux fauteuils roulants :\n\nParking :non",
            longitude
              :
              "0.0000000",
            partners
              :
              "[{\"id\": \"c6290f1a-0330-4757-a633-64db3a7f07bc\", \"name\": \"Full Sport\", \"picture\": \"upload/company/40bed81a-a8e2-4b0b-a238-5b520e6da5ad-73C6A1D2-739D-43FA-B3A0-877B32C09DCA.jpg\"}]",
            picture_id
              :
              "c1f77a7c-6019-4e92-a5af-fbeac80475ef",
            place_name
              :
              "",
            price
              :
              "0",
            short_description
              :
              "Autres activités en ligne ",
            street_address
              :
              "https://google.com",
            title
              :
              "123 Autres activités ",
            zip_code
              :
              ""
          }


        ],
      });
    },
  },
};
