import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { http, util } from "../../../_helpers";
import { appConstants } from "../../../_constants";
import { format } from "date-fns";
const { string } = util;

export * from "./show";
export * from "./show_res";
export * from "./new_res";

declare var $: any;
let fetching = false;
let tab: any = null;

export const AdminSupports = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<any[]>([]);

  const getItems = () => {
    if (fetching) return;
    fetching = true;
    http.get("/chat/support/requests?admin=true&sort=updated_at").then((res) => {
      console.log("ICII", res.data);
      if (Array.isArray(res?.data)) {
        setItems(res.data);
        setTimeout(() => {
          if (($ as any).fn.DataTable.isDataTable($(ref.current) as any)) {
            if (tab) {
              tab.clear();
              tab.destroy();
            }
            $(ref.current).empty();
          }
          tab = ($(ref.current) as any).DataTable(
            appConstants.dataTableSettings
          );
        }, 400);
      }
      setLoading(false);
      fetching = false;
    });
  };

  const onShow = (state: any) => { console.log("closeddd", state.closed);
    navigate("show", {
      state: { ...state, closed: state.closed == "1" ? "Fermé" : "Ouvert" },
    });
  };

  useEffect(() => {
    getItems();
  }, []);
  return (
    <div>
      <h4 className="text-muted mb-4">Support clients </h4>
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="card border-0 rounded-0">
            <div className="card-title mb-1 p-3">
              {!loading && <h5>Liste des requêtes</h5>}
              {loading && <h5 className="text-muted">Chargement en cours</h5>}
            </div>
            <div className="card-body">
              {!loading && (
                <div className="table-responsive-md">
                  <table
                    id="admin_table"
                    ref={ref}
                    className="table table-hover"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Utilisateur</th>
                        <th scope="col">Titre</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Création(GMT)</th>
                        <th scope="col">Dernière mise à jour(GMT)</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">{i + 1}</th>
                            <td>{string(item.user)}</td>
                            <td>{string(item.titre)}</td>
                            <td>{item.closed == "1" ? "Fermé" : "Ouvert"}</td>
                            <td>{string(format(new Date(item.created_at), "dd/MM/yyyy HH:mm:ss"))}</td>
                            <td>{string(format(new Date(item.updated_at), "dd/MM/yyyy HH:mm:ss"))}</td>
                            <td>
                              <a
                                className="btn btn-sm btn-outline-lightning rounded-0 mr-2 "
                                onClick={() => onShow(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
